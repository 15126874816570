<template>
  <master-layout>

    <h1>Fiókod</h1>
    <ion-card>
      <ion-item lines="none">
        <ion-label position="fixed"><b>Név</b></ion-label>
        <ion-label>{{ authData.lastName }} {{ authData.firstName }}</ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-label position="fixed"><b>E-mail</b></ion-label>
        <ion-label>{{ authData.email }}</ion-label>
      </ion-item>
    </ion-card>
    <ion-button class="first-button" expand="block" color="primary" shape="round" @click="$router.push('/platform-ajanlas')">Meghívom a barátaimat</ion-button>
    <ion-button expand="block" color="primary" shape="round" @click="$router.push('/boraszat-ajanlas')">Borászatot ajánlok</ion-button>
    <ion-button expand="block" color="primary" shape="round" @click="$router.push('/ertekeleseim')">Értékeléseim</ion-button>
    <ion-button expand="block" fill="transparent" @click="logout()">Kijelentkezés</ion-button>
    <ion-button expand="block" fill="transparent" @click="$router.push('/informaciok')">Az applikáció információi</ion-button>
    <div class="bottomSpacer"></div>

  </master-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  IonCard, IonItem, IonLabel, IonButton
} from '@ionic/vue'

export default {
  components: {
    IonCard, IonItem, IonLabel, IonButton
  },
  computed: {
    ...mapGetters('auth', {
      authData: 'getAuthData'
    })
  },
  methods: {
    ...mapActions('auth', {
      logoutUser: 'logoutUser'
    }),
    async logout() {
      await this.logoutUser()
      this.$router.push('/app/udvozoljuk')
    }
  }
}
</script>

<style scoped>
ion-card{
  --background: transparent;
}
ion-item{
  --background: transparent;
}
ion-button{
  margin: 5px 40px 10px 40px;
}
.first-button{
  margin-top: 35px;
}
</style>
